import { faCalculator } from "@fortawesome/free-solid-svg-icons/faCalculator";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import {
  faWindowClose,
  faArrowAltCircleLeft,
  faEnvelope,
} from "@fortawesome/free-regular-svg-icons";
import {
  faTwitter,
  faInstagram,
  faLinkedin,
  faFacebookSquare,
  faReddit,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faUsers,
  faUsersCrown,
  faUsersClass,
  faChartNetwork,
  faUserCrown,
  faBuilding,
  faBriefcase,
  faTypewriter,
  faChalkboardTeacher,
  faRocketLaunch,
  faChevronDoubleRight,
  faHeartbeat,
  faBookSpells,
  faBolt,
  faSmile,
  faLasso,
  faFlame,
  faPennant,
  faCauldron,
  faHandPointer,
  faHandPointRight,
  faCookie,
  faCookieBite,
  faRocket,
  faTty,
  faPaperPlane,
  faCommentSmile,
  faMugHot,
  faTasks,
  faPollH,
  faLightbulbOn,
  faUserCircle,
  faRunning,
  faDumbbell,
  faClock,
  faClipboardList,
  faAbacus,
  faAnalytics,
  faQuestion,
  faArrowToBottom,
  faStars,
  faCoins,
  faHandshake,
  faFire,
  faMonument,
  faArrows,
  faAsterisk,
  faThumbsDown,
  faThumbsUp,
  faTimesSquare,
  faCheckSquare,
  faMobile,
  faLanguage,
  faCodeBranch,
  faPaintBrush,
  faClipboard,
  faMeh,
  faBellOn,
  faCommentAltLines,
  faPhone,
  faSearch,
  faLayerGroup,
  faBell,
  faPollPeople,
  faFileUser,
  faInfoSquare,
  faCommentDots,
  faSitemap,
  faFileAlt,
  faReply,
  faExternalLink,
  faLock,
  faDollarSign,
  faCalendarCheck,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";

import {
  faAngleDoubleRight,
  faMousePointer,
  faPlay,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faCheck,
  faArrowRight,
  faArrowLeft,
  faFileDownload,
  faTachometerAlt,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";

export const ICONS = {
  faTimes,
  faFacebookSquare,
  faWhatsapp,
  faReddit,
  faLinkedinIn,
  faLinkedin,
  faAngleDoubleRight,
  faCalculator,
  faChartLine,
  faRocket,
  faTwitter,
  faInstagram,
  faWindowClose,
  faClose: faWindowClose,
  faArrowAltCircleLeft,
  faArrowRight,
  faArrowLeft,
  faCookieBite,
  faCookie,
  faCheck,
  faEnvelope,
  faTachometerAlt,
  faUsers,
  faUsersCrown,
  faUserCrown,
  faUsersClass,
  faChartNetwork,
  faSpinnerThird,
  faBriefcase,
  faBuilding,
  faTypewriter,
  faChalkboardTeacher,
  faRocketLaunch,
  faChevronDoubleRight,
  faHeartbeat,
  faBookSpells,
  faBolt,
  faSmile,
  faLasso,
  faFlame,
  faPennant,
  faCauldron,
  faHandPointer,
  faHandPointRight,
  faMousePointer,
  faTty,
  faPaperPlane,
  faCommentSmile,
  faMugHot,
  faTasks,
  faPollH,
  faLightbulbOn,
  faUserCircle,
  faClock,
  faRunning,
  faDumbbell,
  faClipboardList,
  faAbacus,
  faAnalytics,
  faQuestion,
  faArrowToBottom,
  faStars,
  faCoins,
  faHandshake,
  faFire,
  faMonument,
  faArrows,
  faAsterisk,
  faThumbsDown,
  faThumbsUp,
  faTimesSquare,
  faCheckSquare,
  faMobile,
  faLanguage,
  faCodeBranch,
  faPaintBrush,
  faClipboard,
  faMeh,
  faBellOn,
  faCommentAltLines,
  faPhone,
  faSearch,
  faLayerGroup,
  faBell,
  faPollPeople,
  faFileUser,
  faInfoSquare,
  faCommentDots,
  faSitemap,
  faFileAlt,
  faReply,
  faExternalLink,
  faPlay,
  faLock,
  faDollarSign,
  faFileDownload,
  faCalendarCheck,
};

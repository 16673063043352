import { postman } from "../postman";
import { createBaseEvent } from "./event-creator";

export function extractEventsByTrigger(trigger, events) {
  return events?.filter(evt => evt?.trigger === trigger) || null;
}

export function extractClickEvents(events) {
  return extractEventsByTrigger("click", events);
}

export function persistEventPayload(payload, storageKey) {
  const { sessionStorage } = window;

  if (!sessionStorage) {
    return;
  }

  try {
    const currentRaw = sessionStorage.getItem(storageKey) || null;
    let current = [];

    if (typeof currentRaw === "string") {
      current = JSON.parse(currentRaw);
    }

    current.push(payload);

    sessionStorage.setItem(storageKey, JSON.stringify(current));
  } catch (err) {
    console.error(err);
    // Rescue from invalid dataset
    sessionStorage.removeItem(storageKey);
    return;
  }
}

export function fireEvents(events) {
  if (!(Array.isArray(events) && events.length)) {
    return;
  }

  for (const evt of events) {
    fireEvent(evt);
  }
}

export function fireEvent(evt) {
  const eventPayload = createBaseEvent(evt?.type, evt?.action, evt?.data || {});

  // persistEventPayload(eventPayload, "lpSession");

  postman.next(eventPayload);
}

export function fireClickEvents(events) {
  if (!Array.isArray(events)) {
    return;
  }

  return fireEvents(extractClickEvents(events));
}

export function conversionTrigger(conversion) {
  const { events, redirect } = conversion;

  if (Array.isArray(events)) {
    fireEvents(events);
  }

  if (redirect) {
    const delay =
      Array.isArray(events) && events.length < 10 ? events.length * 100 : 1000;

    window.setTimeout(() => {
      window.location.href = redirect;
    }, delay);
  }
}

export function eventActionMap(evt, actionsMap) {
  if (!(evt && typeof evt === "object")) {
    return;
  }

  const { action } = evt;

  if (!action) {
    return;
  }

  return actionsMap?.[action](evt);
}

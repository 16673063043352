import React, { Fragment } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICONS } from "../../icons.map";

export function IconElement({ icon }) {
  const faIconKey =
    typeof icon === "string" && icon.length
      ? ["fa", icon.charAt(0).toUpperCase(), icon.substr(1)].join("")
      : null;

  const faIcon = ICONS?.[faIconKey];

  if (!faIcon) {
    console.log(`unsupported icon: ${faIconKey}`);
  }

  return <Fragment>{faIcon && <FontAwesomeIcon icon={faIcon} />}</Fragment>;
}

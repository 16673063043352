import { Component } from "react";
import { postman } from "../../postman";
import { filter } from "rxjs/operators";
import { eventActionMap } from "../../events/events.helper";

export class TagManager extends Component {
  #letterbox;

  constructor(props) {
    super(props);

    this.watchDog = this.watchDog.bind(this);
  }

  componentDidMount() {
    if (!this.#letterbox) {
      this.#letterbox = postman
        .pipe(filter(({ type }) => type === "gtm" || type === "cookie-consent"))
        .subscribe(this.watchDog);
    }

    this.initialize();
  }

  componentWillUnmount() {
    this.#letterbox.unsubscribe();
  }

  watchDog(evt) {
    eventActionMap(evt, {
      push: this.dataLayerPush.bind(this),
      consent: this.consent.bind(this),
      initialize: this.initialize.bind(this),
    });
  }

  dataLayerPush({ data }) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  }

  consent({ data }) {
    const { allowedAll, pendingAll } = data || {};

    if (pendingAll) {
      return this.dataLayerPush({ event: "cookie-consent.pending.all" });
    }

    this.gtag("consent", "update", {
      ad_storage: allowedAll ? "granted" : "denied",
      analytics_storage: allowedAll ? "granted" : "denied",
    });

    return this.dataLayerPush({
      event: allowedAll
        ? "cookie-consent.allow.all"
        : "cookie-consent.denied.all",
    });
  }

  gtag(args) {
    // ensure creating a window gtag method tha pushes to datalayer
    if (typeof window.gtag !== "function") {
      window.dataLayer = window.dataLayer || [];
      window.gtag = function gtag() {
        window.dataLayer.push(arguments);
      };
    }

    window.gtag(args);
  }

  placeGtmSnippet() {
    const { containerId } = this.props;

    // Validate if gtm.js is already added
    const gtmScripts = document.querySelectorAll(
      `script[src^="https://www.googletagmanager.com/gtm.js?id=${containerId}"]`
    );

    if (gtmScripts.length) {
      return;
    }

    /*eslint-disable*/
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", containerId);
    /*eslint-enable*/
  }

  initialize() {
    // ensure a dataLayer or keep existing
    window.dataLayer = window.dataLayer || [];

    // Deny consent by default
    this.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 1000,
    });

    this.placeGtmSnippet();
  }

  render() {
    return null;
  }
}

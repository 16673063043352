/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SEO({
  description,
  keywords,
  lang,
  meta,
  title,
  image,
  pathname,
  siteConfig,
}) {
  const metaDescription = description || siteConfig?.description || "";
  const defaultTitle = siteConfig?.siteMetadata?.title || "";
  const canonical = pathname ? `${siteConfig?.siteUrl}${pathname}` : null;

  // TODO: Add json-ld rich snippets to website
  /*

   <script type="application/ld+json">
   {
   "@context": "http://schema.org",
   "@type": "WebSite",
   "name": "Insights-driven Leadership: ECHONOVUM INSIGHTS PLATFORM","alternateName": "echonovum",
   "url": "https:\/\/echonovum.com"
   }
   </script>

   <script type="application/ld+json">
   {
   "@context": "http://schema.org",
   "@type": "Organization",
   "name": "echonovum AG",
   "url": "https:\/\/echonovum.com", "sameAs": ["https://twitter.com/echonovum","https://www.linkedin.com/company/echonovum/","https://www.instagram.com/echonovum/"], "logo": "https:\/\/echonovum.com\/logos\/logo-portrait.png", "address": "Tödistrasse 53\/55, 8002 Zürich"
   }
   </script>

   */

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[
        image && image.publicURL
          ? {
              rel: "image_src",
              href: siteConfig?.siteUrl + image.publicURL,
            }
          : {},
        canonical
          ? {
              rel: "canonical",
              href: canonical,
            }
          : {},
      ]}
      meta={[
        {
          "http-equiv": "cleartype",
          content: "on",
        },
        {
          "http-equiv": "X-UA-Compatible",
          content: "IE=edge,chrome=1",
        },
        {
          name: `format-detection`,
          content: "telephone=no",
        },
        {
          name: `format-detection`,
          content: "address=no",
        },
        {
          name: `viewport`,
          content:
            "width=device-width, initial-scale=1, minimum-scale=1, viewport-fit=cover",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: Array.isArray(keywords) ? keywords.join(",") : null,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: lang || "en",
        },
        image && image.publicURL
          ? {
              name: "og:image",
              content: siteConfig?.siteUrl + image?.publicURL,
            }
          : {},
        image && image.width
          ? {
              name: "og:image:width",
              content: siteConfig?.siteUrl + image?.width,
            }
          : {},
        image && image.height
          ? {
              name: "og:image:height",
              content: siteConfig?.siteUrl + image?.height,
            }
          : {},
        /*
         <meta property="og:site_name" content="Insights-driven Leadership: ECHONOVUM INSIGHTS PLATFORM" />
         */
        canonical
          ? {
              rel: "og:url",
              href: canonical,
            }
          : {},
        {
          name: `twitter:card`,
          content: image ? `summary_large_image` : `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteConfig?.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        image && image.publicURL
          ? {
              name: "twitter:image",
              content: siteConfig?.siteUrl + image?.publicURL,
            }
          : {},
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    publicURL: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  pathname: PropTypes.string,
  siteConfig: PropTypes.object,
};

export default SEO;

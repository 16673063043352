/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import { TagManager } from "./tracking/tagmanager.component";
import { CookieConsent } from "./tracking/cookie-consent.component";
import SEO from "./seo";

function extractImageAssetFromBlocks(image, blocks) {
  if (
    !(
      typeof image === "string" &&
      image.includes(".") &&
      Array.isArray(blocks) &&
      blocks.length
    )
  ) {
    return null;
  }

  const [blockKey, assetKey] = image.split(".");
  const matchingBlock = blocks.find(block => block.key === blockKey);

  if (!matchingBlock) {
    return null;
  }

  return (
    matchingBlock?.assets?.find(asset => asset.key === assetKey)?.src || null
  );
}

const Layout = ({ pageContext, children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          tracking {
            enabled
            gtm
          }
        }
      }
    }
  `);

  const { language, blocks = [], seo, siteConfig } = pageContext || {};
  const { title = "", description = "", keywords = [], image = null } =
    seo || {};

  const seoImage = extractImageAssetFromBlocks(image, blocks);

  const tracking = data.site?.siteMetadata?.tracking;

  return (
    <Fragment>
      {seo && (
        <SEO
          title={title}
          lang={language}
          keywords={keywords}
          description={description}
          pathname={path}
          image={seoImage}
          siteConfig={siteConfig}
        />
      )}

      {tracking?.enabled && tracking?.gtm && (
        <TagManager containerId={tracking.gtm} />
      )}

      <main className="font-sans">{children}</main>

      {tracking?.enabled && tracking?.gtm && <CookieConsent />}
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
